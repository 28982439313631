import axios from 'axios'

import $alert from '@/plugins/alert'
import { blankPic } from '@/config'
import { chooseImage, getImageData, uploadImage } from '@/modules/wechat'

export default class Upload {
  constructor(params = {}) {
    this.uploadUrl = params.uploadUrl || 'https://media2.xinshu.me/upload'

    this.minSize = params.minSize || '640x480'
    this.accept = params.accept || 'image/*'

    this.uploading = null
    this.waiting = []

    this.progress = null
    this.progressInterval = null
  }

  async selectFile(count, accept) {
    this.uploading = null
    if (!window.isDev && window.WeixinJSBridge && window.isAndroid) {
      try {
        const localIds = await chooseImage(count)
        if (localIds.some(pic => !pic)) {
          $alert.error('微信传图组件选择图片失败，请检查图片格式是否有效或是否为云端图片')
          return
        }
        return localIds.filter(Boolean).map(localId => ({localId}))
      } catch (err) {
        $alert.error('微信传图组件调用失败，请尝试点击右上角 ... 菜单刷新页面')
      }
    }

    let lock = false
    return new Promise((resolve) => {
      // create input file
      const el = document.createElement('input')
      el.id = +new Date()
      el.style.display = 'none'
      el.accept = accept || this.accept
      el.setAttribute('type', 'file')

      count = parseInt(count) || 1
      el.multiple = count > 1

      el.addEventListener('change', () => {
        lock = true
        let files = Array.from(el.files).filter(Boolean)
        if (files.length > count) {
          $alert.warn('每次最多只能上传 ' + count + ' 张图片，已忽略多余的图片')
          files = files.slice(0, count)
        }
        resolve(files)
        const oldEl = document.getElementById(el.id)
        if (oldEl) {
          document.body.removeChild(oldEl)
        }
      }, {once: true})

      // file blur
      window.addEventListener('focus', () => {
        setTimeout(() => {
          if (!lock && document.getElementById(el.id)) {
            resolve(null)
            const oldEl = document.getElementById(el.id)
            if (oldEl) {
              document.body.removeChild(oldEl)
            }
          }
        }, 1000)
      }, {once: true})

      document.body.appendChild(el)

      // open file select box
      el.click()
    })
  }

  async putFile(file, key) {
    try {
      clearInterval(this.progressInterval)
      this.progress = null

      let data

      if (file.localId) {
        this.progress = 33
        const mediaId = await uploadImage(file.localId, true)
        this.progress = 67
        data = {mediaId}
      } else {
        data = new FormData()
        data.append('file', file)
      }

      const result = await axios.post(this.uploadUrl, data, {
        onUploadProgress: (e) => {
          const totalLength = e.lengthComputable ? e.total : null
          if (totalLength !== null) {
            this.progress = Math.round((e.loaded * 100) / totalLength)
            this.progress = Math.min(99, this.progress)
          } else {
            this.progressInterval = setInterval(() => {
              this.progress += Math.random() * 5
              this.progress = Math.round(this.progress)
              this.progress = Math.min(99, this.progress)
            }, 500)
          }
        },
        params: {key},
        snakeCase: false
      })

      result.src = '/' + result.key

      if (window.DEBUG) {
        $alert.info(JSON.stringify(result))
      }
      return result
    } finally {
      clearInterval(this.progressInterval)
    }
  }

  async upload(count = 1, cb) {
    try {
      const files = await this.selectFile(count)

      if (!files) {
        console.log('No file selected')
        return
      }

      if (!files.length) {
        console.log('File empty')
        $alert.error('未读取到文件，请检查图片是否为云端存储，请先下载到本地后再试')
        return
      }

      // 预处理文件，获取预览图
      for (const file of files) {
        if (this.accept === 'image/*' && file.type && !/^image\//.test(file.type)) {
          $alert.error(`仅支持上传图片，${file.name} 已被忽略`)
          continue
        }

        let thumbnail = await this.getThumbnail(file)
        const {width, height} = await this.getDimensions(thumbnail)

        if (this.ifTooSmall(width, height)) {
          $alert.error(`对不起，图片尺寸不能小于 ${this.minSize}，${file.name || '文件'} 已被忽略`)
          continue
        }

        if (width * height > 100000000) {
          $alert.error(`对不起，图片尺寸超过最大限制，${file.name || '文件'} 已被忽略`)
          continue
        }

        thumbnail = await this.scaleDown({src: thumbnail, width, height}, 640)
        this.waiting.push({thumbnail, file, key: Date.now() + file.name})
      }

      const results = []

      while (this.waiting.length > 0) {
        this.uploading = this.waiting.shift()
        try {
          const result = await this.putFile(this.uploading.file)
          results.push(result)
          if (typeof cb === 'function') {
            cb(result)
          }
        } catch (err) {
          $alert.error('图片上传失败 ' + err.message)
        }
      }

      if (count <= 1) {
        return results[0]
      }

      return results
    } finally {
      this.waiting = []
      this.uploading = null
      this.progress = null
      clearInterval(this.progressInterval)
    }
  }

  scaleDown({src, width, height}, tWidth) {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas')
      canvas.width = tWidth
      canvas.height = Math.round(tWidth * (height / width))
      const ctx = canvas.getContext('2d')

      const image = new Image()
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        resolve(canvas.toDataURL(window.canUseWebp ? 'webp' : 'jpeg'))
      }
      image.onerror = () => {
        resolve(src)
      }
      image.crossOrigin = true
      image.src = src
    })
  }

  getThumbnail(file) {
    return new Promise(resolve => {
      try {
        if (file.localId) {
          if (/^weixin:/.test(file.localId)) {
            resolve(file.localId)
            return
          }
          getImageData(file.localId)
            .then(res => resolve(res))
            .catch(() => resolve(blankPic))
          return
        }

        if (window.URL && URL.createObjectURL) {
          resolve(URL.createObjectURL(file))
          return
        }

        if (window.FileReader) {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }
          reader.onerror = () => {
            resolve(blankPic)
          }

          reader.readAsDataURL(file)
          return
        }

        resolve(blankPic)
      } catch (err) {
        resolve(blankPic)
      }
    })
  }

  getDimensions(src) {
    return new Promise(resolve => {
      const image = new Image()
      image.onload = () => {
        resolve({width: image.naturalWidth, height: image.naturalHeight})
      }
      image.onerror = () => {
        resolve({width: null, height: null})
      }
      image.src = src
    })
  }

  ifTooSmall(width, height) {
    const [minWidth, minHeight] = this.minSize.split('x')
    return width && height &&
      ((width < minWidth && height < minHeight) || (width < minHeight && height < minWidth))
  }

  destroy() {
    delete this
  }
}
