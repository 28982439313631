<template>
  <div class="upload-zone">
    <draggable class="images" :class="['grid-cols-' + cols, {sorting}]"
               :disabled="dragDisabled"
               prevent-on-filter force-fallback fallback-on-body animation="150"
               draggable=".image" handle=".image" filter=".clickable, .action, .thumb"
               @choose="sorting = true" @unchoose="sorting = false" v-model="images">
      <grid-item class="image" :key="image.id || image.src" v-for="image in images">
        <div class="image-preview"
             :class="{'zoom': readonly, 'draggable': !dragDisabled}"
             @click="preview(image.src)">
          <square :mode="contain ? 'contain' : 'cover'" :src="image.src" border img-size="240">
            <div class="remove" title="点击删除图片" v-if="!readonly && !sorting">
              <b-link @click="removeImage(image)" class="clickable" variant="link">
                <fa icon="times"/>
              </b-link>
            </div>
          </square>
        </div>
      </grid-item>

      <template v-if="!readonly">
        <grid-item class="thumb" v-if="storage.uploading">
          <square :src="storage.uploading.thumbnail" alt="上传中" border mode="contain">
            <div class="mask">
              <template v-if="storage.progress">{{storage.progress}}%</template>
              <fa icon="spinner" spin v-else/>
            </div>
          </square>
        </grid-item>

        <grid-item class="thumb" :key="index" v-for="(item, index) in storage.waiting">
          <square :src="item.thumbnail" border mode="contain">
            <div class="mask">等待中</div>
          </square>
        </grid-item>

        <grid-item class="action" v-if="images.length < max && !storage.uploading">
          <square :disabled="!!storage.uploading" @click="upload" class="add-image">
            <fa icon="ban" v-if="storage.uploading"/>
            <fa icon="plus-circle" v-else/>
          </square>
        </grid-item>

        <grid-item class="action" v-else-if="!hideExceeded">
          <square class="add-image" disabled>已有 {{images.length}} 张</square>
        </grid-item>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { isString } from 'lodash'

import Upload from '@/models/upload'
import preview from '@/mixins/preview-image'

export default {
  name: 'uploadZone',
  mixins: [preview],
  components: {draggable},
  props: {
    value: {
      type: [Array, String, Object],
      default: () => []
    },
    max: {
      type: [Number, String],
      default: 9
    },
    cols: {
      type: [String, Number],
      default: () => window.isMobile ? 4 : 5
    },
    simple: Boolean,
    readonly: Boolean,
    hideExceeded: Boolean,
    draggable: Boolean,
    contain: {
      type: Boolean,
      default: true
    },
    minSize: String
  },
  data() {
    return {
      sorting: false,
      storage: new Upload(),
      resize: 640
    }
  },
  created() {
    if (this.minSize) {
      this.storage.minSize = this.minSize
    }
  },
  beforeDestroy() {
    this.storage.destroy()
  },
  computed: {
    dragDisabled() {
      return !this.draggable || this.images.length <= 1 || !!this.storage.uploading || this.readonly
    },
    images: {
      get() {
        let images = this.value
        if (!Array.isArray(images)) {
          images = [images]
        }
        return images.map(item => {
          if (!item) {
            return null
          }
          if (isString(item)) {
            item = {src: item}
          }
          return item
        }).filter(Boolean)
      },
      set(val) {
        val = val.filter(Boolean)
        if (this.simple) {
          val = val.map(i => i.src || i)
        }
        if (this.max <= 1) {
          val = val[0] || ''
        }
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    async upload() {
      const count = this.max - this.images.length
      await this.storage.upload(count, result => {
        this.images = this.images.concat(result)
      })
      console.log('111', this.images)
      this.$emit('uploaded', this.images)
    },
    removeImage(item) {
      const index = this.images.indexOf(item)
      this.images = this.images.slice(0, index).concat(this.images.slice(index + 1))
      this.$emit('remove', item)
    },

    preview(url) {
      if (this.readonly) {
        return this.$previewImage({url, urls: this.images.map(i => i.src)})
      }
    }
  }
}
</script>

<style lang="scss">
.sortable-ghost, .sortable-chosen {
  .remove {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
.upload-zone {
  user-select: none !important;

  .image-preview {
    &.zoom {
      cursor: zoom-in;
    }

    &.draggable {
      cursor: move;
    }
  }

  .images {
    margin: -4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .grid-item {
      padding: 4px;
    }

    &.sorting {
      .remove {
        display: none !important;
      }

      .action, .add {
        display: none !important;
      }
    }
  }

  @for $i from 1 through 12 {
    .grid-cols-#{$i} > .grid-item {
      width: 100% / $i;
      flex-basis: 100% / $i;
    }
    .grid-cols-sm-#{$i} > .grid-item {
      @include media-breakpoint-up(sm) {
        width: 100% / $i;
        flex-basis: 100% / $i;
      }
    }
  }

  .minus-icon {
    position: relative;
    display: block;
    background: #fff;
    width: 1.25em;
    height: 1.25em;
    border-radius: 100px;
    opacity: .9;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 14px;
      height: 3px;
      background: #555;
    }
  }

  .square {
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 12px;
      margin: auto;
      background-color: rgba(0, 0, 0, .4);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
      color: #eee;
      border-radius: 4px;
    }

    &:hover, &:focus {
      .remove {
        opacity: 1;
      }
    }
  }

  .add-image {
    border: 1px dashed $input-border-color;
  }

  .remove {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    line-height: 1;
    padding: 4px;
    cursor: pointer;
    text-align: center;
    background-color: #999;
    border-bottom-left-radius: 4px;

    a {
      display: block;
      width: 1em;
      height: 1em;
      line-height: 1;
      color: #fff;

      i {
        pointer-events: none;
      }
    }

    @include media-breakpoint-up(md) {
      opacity: .8;
      transition: .3s;
    }
  }
}
</style>
